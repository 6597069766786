import { useState } from 'react';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { usePromotionLayoutsContext } from '../../../context';
import { ImagePartial } from '../../../partials/image';
import { createPromotionHandlebarData, getLocalizedValue } from '../../../utils/transformers';
import { HandlebarTemplate } from '../../handlebarTemplate';
import { Skeleton } from '../../skeleton/Skeleton';
import {
  MultiImagePriceAndPromotionContainer,
  PriceContainer,
  ProductColour,
  ProductColourSelector,
  ProductColourSelectorSection,
  ProductDetails,
  ProductItemMainInfo,
  ProductName,
  ProductShopNowLink,
  SelectedThumbnailBorder,
} from '../commonStylesForCarouselItem';
import { ProductItemPrice } from '../productItemPrice/ProductItemPrice';
import type { ProductCarouselItemProps } from './ProductCarouselItem';
import { ProductItemContainer, ProductItemInfo, ProductMoreColors } from './ProductCarouselItem.styles';
import { MAX_CAROUSEL_ITEMS, ProductCarouselMultiImageContainer } from './ProductCarouselMutlImageContainer';

export function ProductCarouselItemMultiImage({
  isLoading,
  badgesV2,
  imageUrls,
  productColor,
  productName,
  prices,
  slug,
  multiImageButtonText,
  colors,
  productKey,
  handleOnClick,
  priceRanges,
  rangeGroupDescription,
  promotion,
}: Omit<ProductCarouselItemProps, 'isMultiImage' | 'productId' | 'sku'>) {
  const { t } = useTranslation(['lib-global-common']);
  const { promotionLayouts } = usePromotionLayoutsContext();

  const [selectedColorLink, setSelectedColorLink] = useState<number | undefined>();
  const promotionHandlebarData = createPromotionHandlebarData({ promotionLayouts, promotion });

  return (
    <ProductItemContainer forceMount isLoading={isLoading} onClick={handleOnClick}>
      <ProductCarouselMultiImageContainer
        isLoading={isLoading}
        slug={slug}
        productKey={productKey}
        imageUrls={imageUrls}
        productName={productName}
        badgesV2={badgesV2}
      />
      <ProductItemInfo>
        <MultiImagePriceAndPromotionContainer>
          <Link href={`/${slug}/${productKey}-p`} passHref>
            <ProductItemMainInfo tag="a" isLoading={isLoading} forceMount>
              <ProductName
                tag="span"
                skeletonWidth={200}
                skeletonHeight={32}
                isLoading={isLoading}
                title={productName}
                $isMultiImageName
                className="truncated"
              >
                {productName}
              </ProductName>
              <ProductDetails $isMultiImageCategory>
                <Skeleton skeletonWidth={180} tag="p" isLoading={isLoading} title={productColor}>
                  {productColor}
                </Skeleton>
                <Skeleton skeletonWidth={180} tag="p" isLoading={isLoading} title={rangeGroupDescription || ''}>
                  {rangeGroupDescription}
                </Skeleton>
              </ProductDetails>
              <PriceContainer>
                {prices?.map((price, i) => (
                  <ProductItemPrice
                    price={price}
                    key={i}
                    priceRanges={priceRanges}
                    promotionHandlebarData={promotionHandlebarData}
                  />
                ))}
              </PriceContainer>
            </ProductItemMainInfo>
          </Link>
          {promotionHandlebarData && (
            <HandlebarTemplate template={promotionHandlebarData.template} input={promotionHandlebarData.input} />
          )}
        </MultiImagePriceAndPromotionContainer>
        <ProductColourSelectorSection>
          <ProductColourSelector>
            {!colors && isLoading && (
              <Skeleton count={MAX_CAROUSEL_ITEMS} isLoading={isLoading} aspectRatio="1/1" skeletonWidth={40} />
            )}

            {colors &&
              colors?.slice(0, 3).map((color, i) => (
                <Link key={`${i}_${color}`} href={`/${slug}/${color?.key}-p`} passHref>
                  <ProductColour
                    $isSelectedColorLink={selectedColorLink === i}
                    onClick={() => setSelectedColorLink(i)}
                    aria-label={`${t('go.to.pdp.page', { productName })} - ${color?.color}`}
                  >
                    <ImagePartial src={color?.image || ''} layout="fill" objectFit="contain" />
                    <SelectedThumbnailBorder $isSelected={selectedColorLink === i} $isProductColour />
                  </ProductColour>
                </Link>
              ))}

            {colors && colors?.length > 3 && (
              <Link href={`/${slug}/${productKey}-p`} passHref>
                <ProductMoreColors>+{colors.length - 3} more</ProductMoreColors>
              </Link>
            )}
          </ProductColourSelector>
          <Link href={`/${slug}/${productKey}-p`} passHref>
            <ProductShopNowLink
              tag="a"
              isLoading={isLoading}
              aria-label={`${getLocalizedValue(multiImageButtonText)}, ${t('go.to.pdp.page', { productName })}`}
            >
              {getLocalizedValue(multiImageButtonText)}
            </ProductShopNowLink>
          </Link>
        </ProductColourSelectorSection>
      </ProductItemInfo>
    </ProductItemContainer>
  );
}
