import { useEffect } from 'react';
import { useGetCarouselProductsLazyQuery } from '../graphqlDocument/product/carouselProducts.generated';
import { getCtLocale } from '../utils/internationalization';

type GetCarouselProductsProps = {
  context: { locale: string };
  productKey: string[];
  skip?: boolean;
};

export function useGetCarouselProducts({ context, productKey = [], skip }: GetCarouselProductsProps) {
  const input = {
    locale: getCtLocale(context.locale),
    productKeys: productKey.filter((id) => id).map((objectId: string) => objectId.split(' - ')[0]),
  };
  const [getCarouselProduct, { data, called, loading }] = useGetCarouselProductsLazyQuery({
    variables: { input },
  });

  useEffect(() => {
    if (skip || called) return;

    (async () => {
      try {
        await getCarouselProduct();
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getCarouselProduct, skip, called]);

  return { carouselProducts: data?.carouselProducts?.products || [], isLoading: loading };
}
