import type { MultiImageButtonText } from '@amplience/content-types/typings/c-product-carousel';
import type { CarouselProduct } from '../../../codegen/types';
import { ProductCarouselItemMultiImage } from './ProductCarouselItemMultiImage';
import { ProductCarouselItemSingleImage } from './ProductCarouselItemSingleImage';

export interface ProductCarouselItemProps extends CarouselProduct {
  isLoading?: boolean;
  productColor?: string;
  isMultiImage?: boolean;
  multiImageButtonText?: MultiImageButtonText;
  handleOnClick?: () => void;
}

export function ProductItem({
  brand,
  primaryCategory,
  categoryName,
  imageUrls,
  productName,
  prices,
  slug,
  isMultiImage,
  multiImageButtonText,
  colors,
  productKey,
  handleOnClick,
  rangeGroupDescription,
  promotion,
  ...rest
}: ProductCarouselItemProps) {
  const productColor = colors?.find((color) => color?.key === productKey)?.color;

  if (isMultiImage) {
    return (
      <ProductCarouselItemMultiImage
        brand={brand}
        primaryCategory={primaryCategory}
        productKey={productKey}
        categoryName={categoryName}
        imageUrls={imageUrls}
        prices={prices}
        productColor={productColor as string}
        productName={productName}
        slug={slug}
        multiImageButtonText={multiImageButtonText}
        colors={colors}
        handleOnClick={handleOnClick}
        rangeGroupDescription={rangeGroupDescription}
        promotion={promotion}
        {...rest}
      />
    );
  }

  return (
    <ProductCarouselItemSingleImage
      brand={brand}
      primaryCategory={primaryCategory}
      productKey={productKey}
      categoryName={categoryName}
      imageUrls={imageUrls}
      prices={prices}
      productColor={productColor as string}
      productName={productName}
      slug={slug}
      handleOnClick={handleOnClick}
      rangeGroupDescription={rangeGroupDescription}
      promotion={promotion}
      {...rest}
    />
  );
}

export default ProductItem;
