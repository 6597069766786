import { useRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { usePromotionLayoutsContext } from '../../../context';
import { useLocaleContext } from '../../../context/localeContext';
import { useStaticContext } from '../../../context/staticContext';
import { useMediaMatch } from '../../../hooks/useMediaMatch';
import { useTruncated } from '../../../hooks/useTruncated';
import { ImagePartial } from '../../../partials/image';
import { media } from '../../../stylings';
import { sendViewProductImageEvent } from '../../../utils/gtm/events/view_product_image/sendViewProductImageEvent';
import { createPromotionHandlebarData } from '../../../utils/transformers';
import { ImageBadge, TextualBadge } from '../../badges';
import { HandlebarTemplate } from '../../handlebarTemplate';
import { Skeleton } from '../../skeleton/Skeleton';
import { ImageAndBadgeContainer, ProductDetails, ProductLink, ProductName } from '../commonStylesForCarouselItem';
import { ProductItemPrice } from '../productItemPrice/ProductItemPrice';
import type { ProductCarouselItemProps } from './ProductCarouselItem';
import { ProductSingleItemContainer } from './ProductCarouselItem.styles';

export function ProductCarouselItemSingleImage({
  isLoading,
  badgesV2,
  imageUrls,
  productColor,
  productName,
  prices,
  slug,
  productKey,
  handleOnClick,
  priceRanges,
  rangeGroupDescription,
  promotion,
}: Omit<ProductCarouselItemProps, 'isMultiImage' | 'productId' | 'multiImageButtonText' | 'sku'>) {
  const { asPath, query } = useRouter();
  const { host } = useStaticContext();
  const { locale } = useLocaleContext();
  const { promotionLayouts } = usePromotionLayoutsContext();
  const isDesktop = useMediaMatch(media.greaterThan('lg'));
  const { t } = useTranslation(['lib-global-common']);
  const singleProductNameRef = useRef<HTMLHeadingElement>(null);
  const { isTruncated } = useTruncated(singleProductNameRef);

  const isPDP = query.slug?.[query.slug.length - 1].toLocaleLowerCase().endsWith('-p');
  const isPLP = query.slug?.[query.slug.length - 1].toLocaleLowerCase().endsWith('-c');

  const componentType = !isPDP
    ? `${asPath === '/' ? 'Home' : `${asPath.toUpperCase()}`.replace(/\//g, '')} Page`
    : 'PDP';

  const promotionHandlebarData = createPromotionHandlebarData({ promotionLayouts, promotion });

  const onImageClick = (typeName: string, linkUrl: string, index: number) => () => {
    sendViewProductImageEvent({
      component_type: `${isPLP ? 'PLP' : componentType} - ${typeName}`,
      item_name: productName ?? '',
      item_id: productKey ?? '',
      link_url: `https://${host}/${locale}${linkUrl}`,
      index,
    });
  };

  return (
    <ProductSingleItemContainer data-testid={`recommended-product-${productKey}`}>
      <Link href={`/${slug}/${productKey}-p`} passHref>
        <ProductLink
          tag="a"
          isLoading={isLoading}
          forceMount
          onClick={handleOnClick}
          aria-label={t('go.to.pdp.page', { productName })}
        >
          {isLoading && <Skeleton aspectRatio="1/1" isLoading={isLoading} />}
          {!isLoading && imageUrls && (
            <ImageAndBadgeContainer>
              <TextualBadge badge={badgesV2?.textual} singleImageCarousel />
              <ImageBadge badge={badgesV2?.image} />

              <ImagePartial
                src={imageUrls?.[0]}
                hoveredSrc={imageUrls?.[1]}
                width={isDesktop ? 424 : 272}
                height={isDesktop ? 424 : 280}
                onClick={onImageClick('Product Card', `/${slug}/${productKey}-p`, 0)}
                hasLoadingState
              />
            </ImageAndBadgeContainer>
          )}
          <ProductName
            tag="span"
            isLoading={isLoading}
            skeletonWidth={150}
            title={productName}
            ref={singleProductNameRef}
            className={`${isTruncated ? 'truncated' : ''}`}
            data-testid="recommended-product-name"
          >
            {productName}
          </ProductName>
          <ProductDetails>
            <Skeleton
              tag="p"
              skeletonWidth={100}
              isLoading={isLoading}
              title={productColor}
              data-testid="recommended-product-colour"
            >
              {productColor}
            </Skeleton>
            <Skeleton
              tag="p"
              skeletonWidth={100}
              isLoading={isLoading}
              title={rangeGroupDescription || ''}
              data-testid="recommended-product-category"
            >
              {rangeGroupDescription}
            </Skeleton>
          </ProductDetails>
          {prices?.map((price, i) => (
            <ProductItemPrice
              price={price}
              key={i}
              priceRanges={priceRanges}
              promotionHandlebarData={promotionHandlebarData}
            />
          ))}
        </ProductLink>
      </Link>
      {promotionHandlebarData && (
        <HandlebarTemplate template={promotionHandlebarData.template} input={promotionHandlebarData.input} />
      )}
    </ProductSingleItemContainer>
  );
}
