import { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useLocaleContext } from '../../../context/localeContext';
import { useStaticContext } from '../../../context/staticContext';
import { ImagePartial } from '../../../partials/image';
import { sendViewProductImageEvent } from '../../../utils/gtm/events/view_product_image/sendViewProductImageEvent';
import { ImageBadge, TextualBadge } from '../../badges';
import { Skeleton } from '../../skeleton/Skeleton';
import { ImageContainer, ProductLink, SelectedThumbnailBorder } from '../commonStylesForCarouselItem';
import type { ProductCarouselItemProps } from './ProductCarouselItem';
import {
  ProductMultiImageContainerStyled,
  ProductThumbnail,
  ProductThumbnailContainer,
  ProductThumbnailSelector,
} from './ProductCarouselItem.styles';

interface ProductMultiImageContainerProps
  extends Pick<ProductCarouselItemProps, 'slug' | 'productKey' | 'imageUrls' | 'productName' | 'badgesV2'> {
  isLoading?: boolean;
  minThumbnails?: number;
}

export const MAX_CAROUSEL_ITEMS = 4;

export function ProductCarouselMultiImageContainer({
  isLoading,
  slug,
  productKey,
  imageUrls = [],
  productName,
  minThumbnails = 0,
  badgesV2,
}: ProductMultiImageContainerProps) {
  const { asPath, query } = useRouter();
  const { host } = useStaticContext();
  const { locale } = useLocaleContext();
  const { t } = useTranslation(['lib-global-common']);
  const [focusedProductImage, setFocusedProductImage] = useState<string>(imageUrls?.[0] ?? '');
  const images = imageUrls.slice(0, MAX_CAROUSEL_ITEMS);

  const isPDP = query.slug?.[query.slug.length - 1].toLocaleLowerCase().endsWith('-p');
  const isPLP = query.slug?.[query.slug.length - 1].toLocaleLowerCase().endsWith('-c');

  const componentType = !isPDP
    ? `${asPath === '/' ? 'Home' : `${asPath.toUpperCase()}`.replace(/\//g, '')} Page`
    : 'PDP';

  const onImageClick = (typeName: string, linkUrl: string, index: number) => () => {
    sendViewProductImageEvent({
      component_type: `${isPLP ? 'PLP' : componentType} - ${typeName}`,
      item_name: productName ?? '',
      item_id: productKey ?? '',
      link_url: `https://${host}/${locale}${linkUrl}`,
      index,
    });
  };

  const selectProductImage = (image: string, typeName: string, linkUrl: string, index: number) => () => {
    setFocusedProductImage(image);
    sendViewProductImageEvent({
      component_type: `${isPLP ? 'PLP' : componentType} - ${typeName}`,
      item_name: productName ?? '',
      item_id: productKey ?? '',
      link_url: `https://${host}/${locale}${linkUrl}`,
      index,
    });
  };

  return (
    <ProductMultiImageContainerStyled>
      <Link href={`/${slug}/${productKey}-p`} passHref>
        <ProductLink tag="a" isLoading={isLoading} aria-label={t('go.to.pdp.page', { productName })}>
          <TextualBadge badge={badgesV2?.textual} multiImageCarousel />
          <ImageBadge badge={badgesV2?.image} />

          {images.map((image) => (
            <ImageContainer key={image} $isVisible={image === focusedProductImage}>
              <ImagePartial
                alt={image}
                src={image}
                width={1000}
                height={1000}
                aria-hidden
                onClick={onImageClick('Product Card', `/${slug}/${productKey}-p`, 0)}
              />
            </ImageContainer>
          ))}
        </ProductLink>
      </Link>
      <ProductThumbnailSelector>
        {!images.length && isLoading && <Skeleton count={MAX_CAROUSEL_ITEMS} isLoading={isLoading} aspectRatio="1/1" />}

        {!!images.length &&
          imageUrls?.length >= minThumbnails &&
          images.map((thumbnail, i) => (
            <ProductThumbnailContainer key={`${thumbnail}_${i}`}>
              <ProductThumbnail
                type="button"
                onClick={selectProductImage(thumbnail, 'Product Thumbnail', `/${slug}/${productKey}-p`, i)}
                aria-current={thumbnail === focusedProductImage}
                aria-label={t('product.carousel.thumbnail', {
                  thumbnailCount: `${i + 1} / ${Math.min(MAX_CAROUSEL_ITEMS, imageUrls.length)}`,
                })}
              >
                <ImagePartial src={thumbnail} layout="fill" objectFit="cover" aria-hidden />
              </ProductThumbnail>
              <SelectedThumbnailBorder
                $isSelected={`${focusedProductImage}_${i}` === `${thumbnail}_${i}`}
                aria-hidden
              />
            </ProductThumbnailContainer>
          ))}
      </ProductThumbnailSelector>
    </ProductMultiImageContainerStyled>
  );
}
