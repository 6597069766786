import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import type * as Types from '../../codegen/types.js';

const defaultOptions = {} as const;
export type GetCarouselProductsQueryVariables = Types.Exact<{
  input: Types.CarouselProductsInput;
}>;

export type GetCarouselProductsQuery = {
  __typename?: 'Query';
  carouselProducts: {
    __typename?: 'CarouselProductsResponse';
    products: Array<{
      __typename?: 'CarouselProduct';
      brand: string;
      categoryName: string;
      imageUrls: Array<string>;
      productId: string;
      productKey: string;
      productName: string;
      sku: string;
      slug: string;
      rangeGroupDescription?: string | null;
      badges?: Array<{
        __typename?: 'ProductBadge';
        code: string;
        type?: string | null;
        startDate?: string | null;
        endDate?: string | null;
        altText?: string | null;
        label?: string | null;
        url?: string | null;
        imageUrl?: string | null;
        updated?: string | null;
      } | null> | null;
      badgesV2?: {
        __typename?: 'ProductBadgeV2';
        cacheTTL?: number | null;
        textual?: {
          __typename?: 'ProductBadgeTextual';
          url?: string | null;
          textColor?: string | null;
          text?: string | null;
          code?: string | null;
          backgroundColor?: string | null;
        } | null;
        image?: {
          __typename?: 'ProductBadgeImage';
          src?: string | null;
          code?: string | null;
          alt?: string | null;
          url?: string | null;
        } | null;
      } | null;
      primaryCategory?: { __typename?: 'PrimaryCategory'; key?: string | null; name?: string | null } | null;
      prices: Array<{
        __typename?: 'Price';
        actualPrice: {
          __typename?: 'CentPrecisionMoney';
          centAmount: number;
          currencyCode: string;
          fractionDigits: number;
          type: string;
        };
        wasPrice?: {
          __typename?: 'CentPrecisionMoney';
          centAmount: number;
          currencyCode: string;
          fractionDigits: number;
          type: string;
        } | null;
        thenPrice?: {
          __typename?: 'CentPrecisionMoney';
          centAmount: number;
          currencyCode: string;
          fractionDigits: number;
          type: string;
        } | null;
      }>;
      priceRanges?: {
        __typename?: 'CentPrecisionPriceRanges';
        currencyCode: string;
        fractionDigits: number;
        type: string;
        now: { __typename?: 'PriceRange'; minPrice: number; maxPrice: number };
        then?: { __typename?: 'PriceRange'; minPrice: number; maxPrice: number } | null;
        was?: { __typename?: 'PriceRange'; minPrice: number; maxPrice: number } | null;
      } | null;
      colors?: Array<{
        __typename?: 'StyleProduct';
        key: string;
        color?: string | null;
        image?: string | null;
      } | null> | null;
      promotion?: {
        __typename?: 'ProductPromotion';
        discountCode?: string | null;
        layoutId: string;
        pricesStrikethrough: boolean;
        priceRange: {
          __typename?: 'CentPrecisionPriceRange';
          currencyCode: string;
          maxPrice: number;
          fractionDigits: number;
          minPrice: number;
        };
      } | null;
    }>;
  };
};

export const GetCarouselProductsDocument = gql`
  query getCarouselProducts($input: CarouselProductsInput!) {
    carouselProducts(input: $input) {
      products {
        brand
        categoryName
        imageUrls
        productId
        productKey
        productName
        sku
        slug
        brand
        badges {
          code
          type
          startDate
          endDate
          altText
          label
          url
          imageUrl
          updated
        }
        badgesV2 {
          textual {
            url
            textColor
            text
            code
            backgroundColor
          }
          image {
            src
            code
            alt
            url
          }
          cacheTTL
        }
        primaryCategory {
          key
          name
        }
        prices {
          actualPrice {
            centAmount
            currencyCode
            fractionDigits
            type
          }
          wasPrice {
            centAmount
            currencyCode
            fractionDigits
            type
          }
          thenPrice {
            centAmount
            currencyCode
            fractionDigits
            type
          }
        }
        priceRanges {
          currencyCode
          fractionDigits
          type
          now {
            minPrice
            maxPrice
          }
          then {
            minPrice
            maxPrice
          }
          was {
            minPrice
            maxPrice
          }
        }
        colors {
          key
          color
          image
        }
        rangeGroupDescription
        promotion {
          priceRange {
            currencyCode
            maxPrice
            fractionDigits
            minPrice
          }
          discountCode
          layoutId
          pricesStrikethrough
        }
      }
    }
  }
`;

/**
 * __useGetCarouselProductsQuery__
 *
 * To run a query within a React component, call `useGetCarouselProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarouselProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarouselProductsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCarouselProductsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCarouselProductsQuery, GetCarouselProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCarouselProductsQuery, GetCarouselProductsQueryVariables>(
    GetCarouselProductsDocument,
    options,
  );
}
export function useGetCarouselProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCarouselProductsQuery, GetCarouselProductsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCarouselProductsQuery, GetCarouselProductsQueryVariables>(
    GetCarouselProductsDocument,
    options,
  );
}
export type GetCarouselProductsQueryHookResult = ReturnType<typeof useGetCarouselProductsQuery>;
export type GetCarouselProductsLazyQueryHookResult = ReturnType<typeof useGetCarouselProductsLazyQuery>;
export type GetCarouselProductsQueryResult = Apollo.QueryResult<
  GetCarouselProductsQuery,
  GetCarouselProductsQueryVariables
>;
