import styled from 'styled-components';
import { colours, media, spacing } from '../../../stylings';
import { Skeleton } from '../../skeleton/Skeleton';
import { ProductMoreColorsSingleAndMultiImage } from '../commonStylesForCarouselItem';

export const ProductItemContainer = styled(Skeleton)`
  padding-right: 20px;
  position: relative;
`;
export const ProductSingleItemContainer = styled.div`
  margin-bottom: ${spacing.S};
  position: relative;

  & .now-price,
  & .was-price,
  & .then-price,
  & .price-range {
    font-size: 16px;
    line-height: 18px;
  }
`;
export const ProductItemInfo = styled.div`
  margin-top: 24%;
  width: 100%;

  @media ${media.greaterThan('lg')} {
    display: flex;
    gap: ${spacing.XXS};
    justify-content: center;
    margin-top: 140px;
  }
`;
export const ProductMultiImageContainerStyled = styled.div`
  position: relative;

  @media ${media.greaterThan('lg')} {
    max-width: unset;
  }
`;
export const ProductThumbnailSelector = styled.div`
  bottom: 0;
  display: flex;
  gap: ${spacing.XXXS};
  height: 20%;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: calc(100% + 10px);

  @media ${media.greaterThan('lg')} {
    height: 22%;
  }
`;
export const ProductThumbnail = styled.button`
  aspect-ratio: 1 / 1;
  border: none;
  cursor: pointer;
  display: inline-grid;
  height: 100%;
  position: relative;

  &:focus {
    outline: 2px solid ${colours.OUTLINE_BORDER};
  }
`;
export const ProductMoreColors = styled.a`
  color: ${colours.WHITE};
  ${ProductMoreColorsSingleAndMultiImage}
`;
export const ProductThumbnailContainer = styled.div`
  aspect-ratio: 1 / 1;
  border: 1px solid ${colours.MID_GREY};
  position: relative;
`;
